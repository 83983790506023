// React Basic and Bootstrap
import React, { Component } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  Container,
  Row,
  Col,
  Alert,
  Form,
  Input,
  Label,
  Card,
  CardBody,
} from "reactstrap";

//Import Icons
import FeatherIcon from "feather-icons-react";

// import images
import contact from "../../../assets/images/contact.svg";
import image03 from "../../../assets/images/image03.jpg";
import Popup from "../../../components/Layout/popup";


class PageContactOne extends Component {
  constructor(props) {

    super(props);
    this.state = {

      Contactvisible: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.sendMail.bind(this);
    this.callNumber.bind(this);


  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ Contactvisible: true });
  }

  componentDidMount() {
    document.body.classList = "";
    // document.querySelector(".shoppingbtn").classList.add("btn-primary");
    document.getElementById("topnav").classList.add("bg-secondary-x");
    window.addEventListener("scroll", this.scrollNavigation, true);
    AOS.init({ duration: 2000 });
    AOS.refresh();
    // document.querySelector(".cookie-popup").classList.remove("show");   
  }



  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById("topnav");
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add("nav-sticky");
        document.getElementById("topnav").classList.remove("bg-secondary-x");
      } else {
        navBar.classList.remove("nav-sticky");
        document.getElementById("topnav").classList.add("bg-secondary-x");
        // document.querySelector(".shoppingbtn").classList.add("btn-primary");
        // document.querySelector(".shoppingbtn").classList.remove("btn-light");
        // document.querySelector(".settingbtn").classList.add("btn-soft-primary");
      }
    }
  };

  sendMail() {
    window.location.href = "mailto:info@inofinix.io";
  }

  callNumber() {
    window.location.href = "tel:+919663228375";
  }

  render() {
    return (
      <React.Fragment>
        {/* breadcrumb */}
        {/* <section className="bg-half-170 bg-light d-table w-100"
          style={{ background: `url(${image03})` }} >
          <div className="bg-overlay"></div>
          <Container data-aos="fade-up">
            <Row className="mt-5 justify-content-center">
              <Col lg={12} className="text-center">
                <div className="pages-heading">
                  <h4 className="text-white title-dark"> Contact Us </h4>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <div className="form-icon position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div> */}

        <section className="section pb-0" style={{ marginTop: "79px" }}>
          <Container>
            <Row>
              <Col md={4} data-aos='fade-up'>
                <Card className="border-0 text-center features feature-primary feature-clean">
                  <div className="icons text-center mx-auto">
                    <i className="uil uil-phone d-block rounded h3 mb-0"></i>
                  </div>
                  <div className="content mt-4">
                    <h5 className="fw-bold">Phone</h5>
                    <p className="text-muted">
                      Start working with Inofinix that can provide everything
                    </p>
                    <Link
                      to="#"
                      onClick={this.callNumber}
                      className="text-primary"
                    >
                      +91 9663228375
                    </Link>
                  </div>
                </Card>
              </Col>
              <Col md={4} className="mt-4 mt-sm-0 pt-2 pt-sm-0" data-aos='fade-up'>
                <Card className="border-0 text-center features feature-primary feature-clean">
                  <div className="icons text-center mx-auto">
                    <i className="uil uil-envelope d-block rounded h3 mb-0"></i>
                  </div>
                  <div className="content mt-4">
                    <h5 className="fw-bold">Email</h5>
                    <p className="text-muted">
                      Start working with Inofinix that can provide everything
                    </p>
                    <Link
                      to="#"
                      onClick={this.sendMail}
                      className="text-primary"
                    >
                      info@inofinix.io
                    </Link>
                  </div>
                </Card>
              </Col>

              <Col md={4} className="mt-4 mt-sm-0 pt-2 pt-sm-0" data-aos='fade-up'>
                <Card className="border-0 text-center features feature-primary feature-clean">
                  <div className="icons text-center mx-auto">
                    <i className="uil uil-map-marker d-block rounded h3 mb-0"></i>
                  </div>
                  <div className="content mt-4">
                    <h5 className="fw-bold">Location</h5>
                    <p className="text-muted">
                      Dev. center:<br />
                      &#35;1901&#44; 3rd Floor&#44;<br />
                      19th Main, 12th Cross&#44;<br />
                      HSR Layout&#44; Sector 1&#44; <br />
                      Bangalore - 560 102<br />
                    </p>
                    <p className="text-muted">
                      Regd. office:<br />
                      301, 10th cross, Celebrity Paradise<br />
                      Electronic City, Bengaluru, 560100
                    </p>
    {/*                 <Link
                      to={{ pathname: "https://goo.gl/maps/ycPPKFGVLVD3nht36" }} target="blank"

                    >
                      View on Google map
                    </Link> */}
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>

          <Container className="mt-100 mt-60" data-aos='fade-up'>
            <Row className="align-items-center">
              <Col
                lg={5}
                md={6}
                className="mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1"
              >
                <Card className="shadow rounded border-0">
                  <CardBody className="py-5">
                    <h4 className="card-title">Get In Touch !</h4>
                    <div className="custom-form mt-3">
                      <div id="message"></div>
                      <Alert
                        color="primary"
                        isOpen={this.state.Contactvisible}
                        toggle={() => {
                          this.setState({
                            Contactvisible: !this.state.Contactvisible,
                          });
                        }}
                      >
                        Contact details send successfully.
                      </Alert>
                      <Form
                        method="post"
                        onSubmit={this.handleSubmit}
                        name="contact-form"
                        id="contact-form"
                      >
                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Your Name <span className="text-danger">*</span>
                              </Label>
                              <div className="form-icon position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="user"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <Input
                                name="name"
                                id="name"
                                type="text"
                                className="form-control ps-5"
                                placeholder="First Name :"
                                required
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Your Email{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <div className="form-icon position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="mail"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <Input
                                name="email"
                                id="email"
                                type="email"
                                className="form-control ps-5"
                                placeholder="Your email :"
                                required
                              />
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label className="form-label">Subject</Label>
                              <div className="form-icon position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="book"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <Input
                                name="subject"
                                id="subject"
                                className="form-control ps-5"
                                placeholder="Your subject :"
                                required
                              />
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label className="form-label">Comments</Label>
                              <div className="form-icon position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="message-circle"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <textarea
                                name="comments"
                                id="comments"
                                rows="4"
                                className="form-control ps-5"
                                placeholder="Your Message :"
                              ></textarea>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} className="text-center">
                            <div className="d-grid">
                              <input
                                type="submit"
                                id="submit"
                                name="send"
                                className="submitBnt btn btn-primary"
                                value="Send Message"
                              />
                            </div>
                            <div id="simple-msg"></div>

                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg={7} md={{ size: 6, order: 2 }} xs={{ order: 1 }}>
                <Card className="border-0">
                  <CardBody className="p-0">
                    <img src={contact} className="img-fluid" alt="Inofinix" />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>

{/*           <Container className="mt-100 mt-60" fluid data-aos='fade-up'>
            <Row>
              <Col xs={12} className="p-0">
                <Card className="map border-0">
                  <CardBody className="p-0">
                    <iframe
                      title="myFrame"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.9162131866983!2d77.63288521350997!3d12.913106519656138!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15472aef3411%3A0xdeec092ccbf92099!2sINOFINIX%20PRIVATE%20LIMITED!5e0!3m2!1sen!2sin!4v1669004624146!5m2!1sen!2sin"
                      style={{ border: "0" }}
                      className="rounded"
                      allowFullScreen
                    ></iframe>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container> */}
        </section>
        <Popup />
      </React.Fragment>
    );
  }
}
export default PageContactOne;
