// React Basic and Bootstrap
import React, { Component, useEffect } from 'react';
import Popup from '../../../components/Layout/popup';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import avatar from '../../../assets/images/avatar.png';
import code3 from '../../../assets/images/circle.jpg';

// import code2 from '../../../assets/images/coder33.png';
// import code0 from '../../../assets/images/coder44.png';
// import code4 from '../../../assets/images/coder22.png';
// import code1 from '../../../assets/images/coder11.png';
// import code5 from '../../../assets/images/coder5.png';

import gifimage04 from '../../../assets/images/gifimage04.gif';
import gif01 from '../../../assets/images/gif01.gif';
import gifimage01 from '../../../assets/images/gifimage01.gif';
import gif03 from '../../../assets/images/gif03.gif';
import gif5 from '../../../assets/images/gif5.gif';

import AOS from 'aos';
import 'aos/dist/aos.css';
import Slider from 'react-slick';
import Sectiontwo from './Sectiontwo';
import Sectionthree from './Sectionthree';
import Fade from 'react-reveal/Fade';
import { Motion, spring } from 'react-motion';
import { Link } from 'react-router-dom';
import { indigo } from '@mui/material/colors';
//Import Images

const springSettings = { stiffness: 170, damping: 26 };
const NEXT = 'show-next';
const Y_OFFSET_DIFFERENCE = 300;
// let photoList = [code0, code1, code2, code4, code5];

let photoList = [gifimage04, gif01, gifimage01, gif03, gif5];

let dataAosList = ['fade-right', 'zoom-out', 'zoom-in-down', 'flip-left'];

class Product extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataAos: dataAosList[0], // Initialize dataAos state
      dataSource: photoList[0], // Initialize dataSource state
      photos: [
        [500, 350],
        [800, 600],
        [800, 400],
        [700, 500],
        [100, 350],
        [400, 200],
      ],
      currPhoto: 0,
      dataSource: photoList[0],
      dataAos: dataAosList[0],

      imageStyle: {
        height: '30%',
        width: '24%',
        overflowY: 'scroll',
        position: 'fixed',
        top: '336px',
        // marginLeft:'270px',
      },
    };
  }

  componentDidMount() {
    AOS.init(); // Initialize AOS
    window.addEventListener('scroll', this.scrollNavigation, true);
    document.body.classList = '';
    // document.querySelector(".shoppingbtn").classList.add("btn-primary");
    document.getElementById('topnav').classList.add('bg-secondary-x');
    window.addEventListener('scroll', this.scrollNavigation, true);
    // AOS.init();
    // AOS.init({ duration: 9000 });
    // AOS.refresh();
    // document.querySelector(".cookie-popup").classList.remove("show");
  }
  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollNavigation, true);
  }
  // console.log(dataSource);

  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById('topnav');
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        console.log(window.pageYOffset, 'hhhhh');
        if (window.pageYOffset > 954) {
          this.setState({
            ...this.state,
            imageStyle: {
              height: '300px',
              width: '0%',
              overflowY: 'scroll',
              position: 'absolute',
              top: '44%',
              marginLeft: '10px',
            },
          });
        }
        if (window.pageYOffset < 1400) {
          this.setState({
            ...this.state,
            imageStyle: {
              height: '300px',
              width: '24%',
              overflowY: 'scroll',
              position: 'fixed',
              top: '336px',
              marginTop: '-70px',
              // marginLeft: '270px',
            },
          });
        }
        navBar.classList.add('nav-sticky');
        document.getElementById('topnav').classList.remove('bg-secondary-x');
        const position = window.pageYOffset;
        const index =
          Math.floor(position / Y_OFFSET_DIFFERENCE) % photoList.length;
        const selectedSource = photoList[index];
        const selectedAos = dataAosList[index % (dataAosList.length - 1)];

        if (selectedSource === this.state.dataSource) {
          return;
        }
        this.setState({
          ...this.state,
          dataSource: selectedSource,
          dataAos: selectedAos,
        });
      } else {
        navBar.classList.remove('nav-sticky');
        document.getElementById('topnav').classList.add('bg-secondary-x');
      }
    }
  };
  handleChange = ({ target: { value } }) => {
    this.setState({ currPhoto: value });
  };
  clickHandler = (btn) => {
    console.log(btn);
    let photoIndex =
      btn === NEXT ? this.state.currPhoto + 1 : this.state.currPhoto - 1;
    photoIndex = photoIndex >= 0 ? photoIndex : this.state.photos.length - 1;
    photoIndex = photoIndex >= this.state.photos.length ? 0 : photoIndex;
    console.log(photoIndex);
    this.setState({
      currPhoto: photoIndex,
    });
  };
  render() {
    // const photoArray = [code0, code1, code2, code3, code4];

    const photoArray = [gifimage04, gif01, gifimage01, gif03, gif5];

    const { photos, currPhoto } = this.state;
    const [currWidth, currHeight] = photos[currPhoto];
    const widths = photos.map(([origW, origH]) => (currHeight / origH) * origW);
    const leftStartCoords = widths
      .slice(0, currPhoto)
      .reduce((sum, width) => sum - width, 0);
    const configs = [];
    photos.reduce((prevLeft, [origW, origH], i) => {
      configs.push({
        left: spring(prevLeft, springSettings),
        height: spring(currHeight, springSettings),
        width: spring(widths[i], springSettings),
      });
      return prevLeft + widths[i];
    }, leftStartCoords);
    return (
      <React.Fragment>
        <div style={{ backgroundColor: 'whitesmoke' }}>
          <Container>
            <section className="color-whitesmoke" style={{ marginTop: '90px' }}>
              <div className="main-content-wrapper cf">
                <div
                  className="sqs-layout sqs-grid-12 columns-12"
                  id="page-59fca8d46c3194f508016e47"
                >
                  <div className="row sqs-row">
                    <div className="textheadingresponse col sqs-col-12 span-12">
                      <div
                        className="sqs-block html-block sqs-block-html"
                        id="block-yui_3_17_2_87_1509726951338_3735"
                      >
                        <div
                          className="sqs-block-content"
                          style={{ marginRight: '126px', marginTop: '50px' }}
                        >
                          <svg
                            style={{ display: 'flex' }}
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="#2f55d4"
                            className="icon-dark-indigo-500 icon-light-indigo-200 mx-auto mb-[8px] hidden md:block"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M3 17H2v.1A2.9 2.9 0 0 0 4.9 20h14.2a2.9 2.9 0 0 0 2.9-2.9V17H3Z"
                              fill="#D0D2E0"
                              className="icon-light"
                            ></path>
                            <path
                              d="M21 17H3m18 0h1v.1m-1-.1V6M3 17H2v.1m1-.1V6m2-2h14M5 4a2 2 0 0 0-2 2m2-2v0a2 2 0 0 0-2 2v0m16-2a2 2 0 0 1 2 2m-2-2v0a2 2 0 0 1 2 2v0m1 11.1a2.9 2.9 0 0 1-2.9 2.9m2.9-2.9v0a2.9 2.9 0 0 1-2.9 2.9v0m0 0H4.9m0 0A2.9 2.9 0 0 1 2 17.1M4.9 20v0A2.9 2.9 0 0 1 2 17.1v0"
                              className="icon-dark"
                              stroke="#2f55d4"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <circle
                              cx="12"
                              cy="7"
                              r="1"
                              fill="#2f55d4"
                              className="icon-dark"
                            ></circle>
                          </svg>
                          <h3
                            style={{
                              textAlign: 'center',
                              whiteSpace: 'pre-wrap',
                              color: 'grey',
                            }}
                            className="headingresponisve"
                          >
                            Introducing the{' '}
                            <a href="inofinix.io">Inofinix App</a>
                          </h3>
                          <h1
                            style={{
                              textAlign: 'center',
                              whiteSpace: 'pre-wrap',
                            }}
                            className="headingresponisve2"
                          >
                            <b>
                              {' '}
                              Write, run, and debug{' '}
                              <span style={{ color: '#2f55d4' }}>
                                like a pro{' '}
                              </span>
                            </b>
                            <br></br>
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="z-20 ml-[-3px] h-[12px] w-[12px] origin-center transform rounded-full border-[2px] transition-transform scale-70 border-gray-200 bg-red group-hover:border-indigo-500"></div>
              <div
                style={
                  window.pageYOffset > 1400
                    ? {
                        position: 'absolute',
                        height: 1643,
                        width: '3px',
                        background: 'rgb(47, 85, 212)',
                        marginLeft: '494px',
                      }
                    : {
                        position: 'absolute',
                        height: window.pageYOffset,
                        width: '4px',
                        background: 'rgb(47, 85, 212)',
                        marginLeft: '494px',
                      }
                }
              ></div>
              {/* <div
                style={{
                  content: '',
                  position: 'absolute',
                  height: '1586px',
                  top: '279px',
                  bottom: '0',
                  left: '46.2%',
                  borderLeft: ' 2px dashed #444 ',
                }}
                className="dashedlineresponsive"
              ></div> */}
              <div
                className="contentresponsive"
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(2,4ppx)',
                  marginTop: '185px',
                }}
              >
                <div>
                  <img
                    data-aos={this.state.dataAos}
                    src={this.state.dataSource}
                    className="img-fluid imageresponsive"
                    alt=""
                    style={this.state.imageStyle}
                  />
                </div>
                {/* <div>
                  <img
                    src={this.state.dataSource} // Use the dataSource state
                    className="img-fluid imageresponsive"
                    alt=""
                    style={{
                      ...this.state.imageStyle,
                      opacity: this.state.dataSource === gif01 ? 1 : 0,
                    }}
                  />
                  <img
                    src={this.state.dataSource} // Use the dataSource state
                    className="img-fluid imageresponsive"
                    alt=""
                    style={{
                      ...this.state.imageStyle,
                      opacity: this.state.dataSource === gifimage04 ? 1 : 0,
                    }}
                  />
                  <img
                    src={this.state.dataSource} // Use the dataSource state
                    className="img-fluid imageresponsive"
                    alt=""
                    style={{
                      ...this.state.imageStyle,
                      opacity: this.state.dataSource === gifimage01 ? 1 : 0,
                    }}
                  />
                  <img
                    src={this.state.dataSource} // Use the dataSource state
                    className="img-fluid imageresponsive"
                    alt=""
                    style={{
                      ...this.state.imageStyle,
                      opacity: this.state.dataSource === gif03 ? 1 : 0,
                    }}
                  />
                  <img
                    src={this.state.dataSource} // Use the dataSource state
                    className="img-fluid imageresponsive"
                    alt=""
                    style={{
                      ...this.state.imageStyle,
                      opacity: this.state.dataSource === gif5 ? 1 : 0,
                    }}
                  />
                </div> */}

                <div>
                  <img
                    src={avatar}
                    alt=""
                    width="30px"
                    height="30px"
                    style={
                      window.pageYOffset > 1643
                        ? {
                            display: 'none',
                          }
                        : {
                            position: 'fixed',
                            transform: 'translate(-50%,-640%)',
                            left: '46.3%',
                            zIndex: '1',
                            // border: '1px solid red'
                          }
                    }
                    className="avatarresponsive"
                  />
                  <img
                    src={avatar}
                    alt=""
                    width="30px"
                    height="30px"
                    style={
                      window.pageYOffset > 3100 || window.pageYOffset < 1900
                        ? {
                            display: 'none',
                          }
                        : {
                            position: 'fixed',
                            transform: 'translate(-50%,-1010%)',
                            left: '46.3%',
                            zIndex: '1',
                            // border: '1px solid green'
                          }
                    }
                    className="avatarresponsive"
                  />
                </div>
                <div
                  style={{
                    marginBottom: '250px',
                    marginTop: '-130px',
                    marginLeft: '-11px',
                  }}
                >
                  <div
                    className="paragraphresponsive"
                    style={{
                      marginLeft: '560px',
                      marginBottom: '150px',
                      width: 'max-content',
                    }}
                  >
                    {/* ...........First Image......... */}
                    {/* <div>
                      <img
                        data-aos="flip-left"
                        src={code0}
                        style={{
                          width: '430px',
                          height: '242px',
                          marginBottom: '-294px',
                          marginLeft: '-570px',
                        }}
                      />
                    </div> */}
                    {/* <div>
                      <img
                        src={code3}
                        alt=""
                        width="15px"
                        height="15px"
                        style={{
                          position: 'absolute',
                          transform: 'translate(-50%,20%)',
                          left: '46.3%',
                        }}
                        className="dashedlineresponsive2"
                      />
                    </div> */}
                    <h3
                      style={{ color: '#2f55d4' }}
                      className="font-primary m-0  text-left text-[20px] font-semibold leading-[32px] transition transition-colors text-indigo-500"
                    >
                      Test modern apps directly in the browser
                    </h3>
                    <p className="mt-[8px] overflow-hidden text-[16px] leading-[24px] text-gray-700">
                      Watch your end-to-end and component tests run in real-time
                      as you develop <br></br>your applications. Inofinix’s
                      simple, yet powerful API runs as fast as your <br></br>
                      browser can render content.
                    </p>
                    <Link
                      className="mt-[12px] flex items-center gap-[8px] text-[16px] font-medium leading-[24px] text-indigo-500"
                      to={{ pathname: 'https://inofinix.io/index' }}
                      target="_blank"
                    >
                      {' '}
                      Learn more
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="m9 12 4-4m0 0L9 4m4 4H3"
                          stroke="currentColor"
                          className="icon-dark"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </Link>
                  </div>
                  <div style={{ marginBottom: '250px' }}>
                    <div
                      className="paragraphresponsive"
                      style={{
                        marginLeft: '560px',
                        marginBottom: '-100px',
                        width: 'max-content',
                      }}
                    >
                      {/* <div>
                        <img
                          data-aos={this.state.dataAos}
                          src={code2}
                          style={{
                            width: '430px',
                            height: '242px',
                            marginBottom: '-116px',
                            marginLeft:'-570px',
                          }}
                        />
                      </div> */}

                      {/* ...........Second Image......... */}
                      {/* <div>
                        <img
                          data-aos="fade-up"
                          src={code1}
                          style={{
                            width: '430px',
                            height: '242px',
                            marginBottom: '-294px',
                            marginLeft: '-570px',
                          }}
                        />
                      </div> */}
                      <div>
                        <img
                          src={code3}
                          alt=""
                          width="15px"
                          height="15px"
                          style={{
                            position: 'absolute',
                            transform: 'translate(-50%,20%)',
                            left: '46.3%',
                          }}
                          className="dashedlineresponsive2"
                        />
                      </div>
                      <h3
                        style={{ color: '#2f55d4' }}
                        className="font-primary m-0  text-left text-[20px] font-semibold leading-[32px] transition transition-colors text-indigo-500"
                      >
                        Write your first passing test in minutes
                      </h3>
                      <p className="mt-[8px] overflow-hidden text-[16px] leading-[24px] text-gray-700">
                        Because Inofinix runs directly in the browser, you can
                        debug failed tests using <br></br>the in-browser
                        developer tools you already know and love.
                      </p>
                      <Link
                        className="mt-[12px] flex items-center gap-[8px] text-[16px] font-medium leading-[24px] text-indigo-500"
                        to={{ pathname: 'https://inofinix.io/index' }}
                        target="_blank"
                      >
                        {' '}
                        Learn more
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="m9 12 4-4m0 0L9 4m4 4H3"
                            stroke="currentColor"
                            className="icon-dark"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      </Link>
                    </div>
                  </div>
                  <div style={{ marginBottom: '250px' }}>
                    <div
                      className="paragraphresponsive"
                      style={{
                        marginLeft: '560px',
                        marginBottom: '-100px',
                        width: 'max-content',
                      }}
                    >
                      {/* <div>
                        <img
                          data-aos={this.state.dataAos}
                          src={code2}
                          style={{
                            width: '430px',
                            height: '242px',
                            marginBottom: '-116px',
                            marginLeft:'-570px',
                          }}
                        />
                      </div> */}

                      {/* ...........Third Image......... */}
                      {/* <div>
                        <img
                          data-aos="zoom-in"
                          src={code2}
                          style={{
                            width: '430px',
                            height: '242px',
                            marginBottom: '-294px',
                            marginLeft: '-570px',
                          }}
                        />
                      </div> */}
                      <div>
                        <img
                          src={code3}
                          alt=""
                          width="15px"
                          height="15px"
                          style={{
                            position: 'absolute',
                            transform: 'translate(-50%,20%)',
                            left: '46.3%',
                          }}
                          className="dashedlineresponsive2"
                        />
                      </div>
                      <h3
                        style={{ color: '#2f55d4' }}
                        className="font-primary m-0  text-left text-[20px] font-semibold leading-[32px] transition transition-colors text-indigo-500"
                      >
                        Debug failures directly in the browser
                      </h3>
                      <p className="mt-[8px] overflow-hidden text-[16px] leading-[24px] text-gray-700">
                        Because Inofinix runs directly in the browser, you can
                        debug failed tests using <br></br>the in-browser
                        developer tools you already know and love.
                      </p>
                      <Link
                        className="mt-[12px] flex items-center gap-[8px] text-[16px] font-medium leading-[24px] text-indigo-500"
                        to={{ pathname: 'https://inofinix.io/index' }}
                        target="_blank"
                      >
                        {' '}
                        Learn more
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="m9 12 4-4m0 0L9 4m4 4H3"
                            stroke="currentColor"
                            className="icon-dark"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      </Link>
                    </div>
                  </div>
                  <div style={{ marginBottom: '250px' }}>
                    <div
                      className="paragraphresponsive"
                      style={{
                        marginLeft: '560px',
                        marginBottom: '-100px',
                        width: 'max-content',
                      }}
                    >
                      {/* <div>
                        <img
                          data-aos="zoom-in"
                          src={code2}
                          style={{
                            width: '430px',
                            height: '242px',
                            marginBottom: '-116px',
                            marginLeft:'-570px',
                          }}
                        />
                      </div> */}

                      {/* ...........Fourth Image......... */}
                      {/* <div>
                        <img
                          data-aos="flip-down"
                          src={gif5}
                          style={{
                            width: '430px',
                            height: '242px',
                            marginBottom: '-294px',
                            marginLeft: '-570px',
                          }}
                        />
                      </div> */}
                      <div>
                        <img
                          src={code3}
                          alt=""
                          width="15px"
                          height="15px"
                          style={{
                            position: 'absolute',
                            transform: 'translate(-50%,20%)',
                            left: '46.3%',
                          }}
                          className="dashedlineresponsive2"
                        />
                      </div>
                      <h3
                        style={{ color: '#2f55d4' }}
                        className="font-primary m-0  text-left text-[20px] font-semibold leading-[32px] transition transition-colors text-indigo-500"
                      >
                        Eliminate flaky tests with ease
                      </h3>
                      <p className="mt-[8px] overflow-hidden text-[16px] leading-[24px] text-gray-700">
                        Inofinix deterministically interacts with your
                        application the same way <br></br>as your users so you
                        can discover intermittent bugs before your users do
                      </p>
                      <Link
                        className="mt-[12px] flex items-center gap-[8px] text-[16px] font-medium leading-[24px] text-indigo-500"
                        to={{ pathname: 'https://inofinix.io/index' }}
                        target="_blank"
                      >
                        {' '}
                        Learn more
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="m9 12 4-4m0 0L9 4m4 4H3"
                            stroke="currentColor"
                            className="icon-dark"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      </Link>
                    </div>
                  </div>
                  <div>
                    <div
                      className="paragraphresponsive"
                      style={{
                        marginLeft: '560px',
                        marginBottom: '-100px',
                        width: 'max-content',
                      }}
                    >
                      {/* ...........Fifth Image......... */}
                      {/* <div>
                        <img
                          data-aos="zoom-up"
                          src={gif5}
                          style={{
                            width: '430px',
                            height: '242px',
                            marginBottom: '-294px',
                            marginLeft: '-570px',
                          }}
                        />
                      </div> */}
                      <div
                        style={{
                          display: 'flex',
                          gap: '60px',
                          marginBottom: '22px',
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 64 64"
                          style={{ marginTop: 'auto ' }}
                        >
                          <g
                            transform="matrix(2.791226 0 0 2.791226 71.368826 91.588681)"
                            fill="#161616"
                          >
                            <circle r="2.73" cy="-21.349" cx="-14.275" />
                            <path d="M-14.275-32.813c-5.342 0-9.83 3.654-11.103 8.598a.56.56 0 0 0-.019.136c0 .3.244.546.546.546h4.623a.54.54 0 0 0 .495-.32s.007-.013.01-.02c.953-2.056 3.034-3.483 5.45-3.483 3.317 0 6.006 2.688 6.006 6.005s-2.688 6.005-6.005 6.005c-2.415 0-4.496-1.427-5.45-3.483-.003-.007-.01-.02-.01-.02-.088-.194-.282-.32-.495-.32h-4.623c-.302 0-.546.244-.546.546a.54.54 0 0 0 .019.136c1.273 4.944 5.762 8.598 11.103 8.598C-7.942-9.89-2.8-15.022-2.8-21.353s-5.133-11.464-11.464-11.464z" />
                          </g>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 32 32"
                          width="40px"
                          height="40px"
                          fillRule="evenodd"
                        >
                          <path
                            fillRule="evenodd"
                            d="M 16 4 C 9.371094 4 4 9.371094 4 16 C 4 21.300781 7.4375 25.800781 12.207031 27.386719 C 12.808594 27.496094 13.027344 27.128906 13.027344 26.808594 C 13.027344 26.523438 13.015625 25.769531 13.011719 24.769531 C 9.671875 25.492188 8.96875 23.160156 8.96875 23.160156 C 8.421875 21.773438 7.636719 21.402344 7.636719 21.402344 C 6.546875 20.660156 7.71875 20.675781 7.71875 20.675781 C 8.921875 20.761719 9.554688 21.910156 9.554688 21.910156 C 10.625 23.746094 12.363281 23.214844 13.046875 22.910156 C 13.15625 22.132813 13.46875 21.605469 13.808594 21.304688 C 11.144531 21.003906 8.34375 19.972656 8.34375 15.375 C 8.34375 14.0625 8.8125 12.992188 9.578125 12.152344 C 9.457031 11.851563 9.042969 10.628906 9.695313 8.976563 C 9.695313 8.976563 10.703125 8.65625 12.996094 10.207031 C 13.953125 9.941406 14.980469 9.808594 16 9.804688 C 17.019531 9.808594 18.046875 9.941406 19.003906 10.207031 C 21.296875 8.65625 22.300781 8.976563 22.300781 8.976563 C 22.957031 10.628906 22.546875 11.851563 22.421875 12.152344 C 23.191406 12.992188 23.652344 14.0625 23.652344 15.375 C 23.652344 19.984375 20.847656 20.996094 18.175781 21.296875 C 18.605469 21.664063 18.988281 22.398438 18.988281 23.515625 C 18.988281 25.121094 18.976563 26.414063 18.976563 26.808594 C 18.976563 27.128906 19.191406 27.503906 19.800781 27.386719 C 24.566406 25.796875 28 21.300781 28 16 C 28 9.371094 22.628906 4 16 4 Z"
                          />
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 48 48"
                          width="34"
                          height="34"
                        >
                          <path fill="#e53935" d="M24 43L16 20 32 20z" />
                          <path fill="#ff7043" d="M24 43L42 20 32 20z" />
                          <path fill="#e53935" d="M37 5L42 20 32 20z" />
                          <path fill="#ffa726" d="M24 43L42 20 45 28z" />
                          <path fill="#ff7043" d="M24 43L6 20 16 20z" />
                          <path fill="#e53935" d="M11 5L6 20 16 20z" />
                          <path fill="#ffa726" d="M24 43L6 20 3 28z" />
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="32"
                          preserveAspectRatio="xMidYMid"
                          width="32"
                          viewBox="-0.9662264221278978 -0.5824607696358868 257.93281329857973 230.8324730411935"
                        >
                          <linearGradient
                            id="a"
                            x1="108.633%"
                            x2="46.927%"
                            y1="13.818%"
                            y2="78.776%"
                          >
                            <stop offset=".18" stopColor="#0052cc" />
                            <stop offset="1" stopColor="#2684ff" />
                          </linearGradient>
                          <g fill="none">
                            <path d="M101.272 152.561h53.449l12.901-75.32H87.06z" />
                            <path
                              d="M8.308 0A8.202 8.202 0 0 0 .106 9.516l34.819 211.373a11.155 11.155 0 0 0 10.909 9.31h167.04a8.202 8.202 0 0 0 8.201-6.89l34.82-213.752a8.202 8.202 0 0 0-8.203-9.514zm146.616 152.768h-53.315l-14.436-75.42h80.67z"
                              fill="#2684ff"
                            />
                            <path
                              d="M244.61 77.242h-76.916l-12.909 75.36h-53.272l-62.902 74.663a11.105 11.105 0 0 0 7.171 2.704H212.73a8.196 8.196 0 0 0 8.196-6.884z"
                              fill="url(#a)"
                            />
                          </g>
                        </svg>
                        <svg
                          style={{ marginTop: '-5px' }}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 48 30"
                          width="40px"
                          height="40px"
                        >
                          <path
                            fill="#ffe0b2"
                            d="M15.2,33.5c-0.5,0.3-8.2,5.2-8.2,5.5c0,0.4,0,0.4,0,0.4L8.4,43h31.2c0,0,1.5-4.8,1.4-6 c0-0.6-5-3.9-5-3.9s3.9-3.9,4-12.4c0-0.8,0-1.6-0.1-2.4C39.2,8.7,33.1,5.3,26.7,5c-6.5-0.2-10.1,2.8-10.1,2.8s-4.1,1.4-4.5,4.7 c0,0-3.2,0.6-1.5,7.1C9.3,20.6,9,22,9,23c0,1.3,0.4,2.8,1.2,3.7c1.7,1.8,3.1,1.4,3.1,1.4S13.9,31.3,15.2,33.5z"
                          />
                          <path
                            fill="#f44336"
                            d="M36.8,36.9C36.6,35.6,36,35,34.7,35h-0.1l-0.1,0c-0.4,0.1-1,0.3-2.7,1.5l-1,0.7 C30.6,37.1,30.3,37,30,37c-0.5,0-0.9,0.2-1.3,0.5l-1-0.5L26,36.1c-0.9-0.4-1.8-0.7-2.5-0.7c-0.5,0-0.9,0.1-1.3,0.3 c-0.5,0.4-0.7,1-0.6,1.8c0,0.4,0.1,0.9,0.1,1.3c0.2,2.1,0.3,3.6,2.3,3.7c0.1,0,0.2,0,0.3,0c1.1,0,2.4-0.4,3.6-1.3l0.9-0.6 c0.3,0.2,0.7,0.4,1.1,0.4c0.5,0,0.9-0.2,1.2-0.4l1.4,0.3c0.6,0.1,1.1,0.2,1.6,0.2l0.4,0c0.5,0,1,0,1.5-0.3c0.6-0.3,0.9-1,0.9-2.1 c0-0.5-0.1-1-0.1-1.4L36.8,36.9z"
                          />
                          <path
                            fill="#fff"
                            d="M21.7,38.9c0-0.4-0.1-0.8-0.1-1.3c-0.1-0.6,0-1,0.2-1.4c-5-0.1-6.6-2.7-6.6-2.7s-0.3,1.6,1.1,3.4 c2.1,2.5,5.3,2.8,5.3,2.8h0.2C21.8,39.5,21.8,39.2,21.7,38.9z"
                          />
                          <path
                            fill="#546e7a"
                            d="M22.9,43l-0.7-1.4c-0.1-0.3-0.2-0.6-0.3-1.1l-0.1-0.7l-0.7-0.1c-0.9-0.2-3.1-0.8-4.7-2.7 c-0.5-0.6-0.8-1.3-1-2L15,33.7l-1.1,0.7c-2,1.3-5.3,3.4-6.4,4.3l-0.6,0.5L8.4,43L22.9,43L22.9,43z"
                          />
                          <path
                            fill="#455a64"
                            d="M12.2,43h10.7l-0.7-1.4c-0.1-0.3-0.2-0.6-0.3-1.1l-0.1-0.7l-0.7-0.1c-0.9-0.2-3.1-0.8-4.7-2.7 c-0.1-0.1-0.2-0.3-0.3-0.4L11,40L12.2,43z"
                          />
                          <path
                            fill="#546e7a"
                            d="M40.7,36.5c-0.6-0.6-2.4-1.9-3.1-2.4l-0.7-0.5c-0.2,0.2-0.6,0.7-1.3,1.2l0.7,0.7 c0.3,0.3,0.5,0.8,0.6,1.3l0,0.3c0.1,0.4,0.1,0.9,0.1,1.4c0,1.7-0.6,2-0.9,2.1c-0.2,0.1-0.5,0.2-0.8,0.2l0.1,2h4.1l0.2-0.7 c0.5-1.6,1.1-3.9,1.2-4.9l0-0.5L40.7,36.5z"
                          />
                          <path
                            fill="#455a64"
                            d="M39.6,43l0.2-0.7c0.3-0.8,0.5-1.9,0.8-2.8l-3.7-2c0,0.3,0.1,0.7,0.1,1.1c0,1.7-0.6,2-0.9,2.1 c-0.2,0.1-0.5,0.2-0.8,0.2l0.1,2L39.6,43L39.6,43z"
                          />
                          <path
                            fill="#616161"
                            d="M10.3,19.8C10.3,19.8,10.4,19.7,10.3,19.8L10.3,19.8l0.1-0.1c0.5-0.4,1.2-0.7,1.8-0.7 c0.6,0,0.9,0.2,1.2,0.3c1.3,0.7,1.6,2,1.7,2.9l0,0l0,0.1l0,0.1c0.6-0.2,1.2-0.4,1.4-0.5c0-0.1,0-0.3-0.1-0.6 c-0.1-0.4-0.2-0.9-0.4-1.4c-0.4-1.1-0.3-2.5,0.3-4.3l0.1-0.2c0.1-0.4,0.3-0.8,0.3-1.1c0-0.3,0.1-0.6,0.1-0.7c0-0.3-0.1-0.5-0.2-0.8 l-0.2-0.5l0.3-0.5c0.9-1.5,4.2-6.5,10.9-6.5c0.2,0,0.4,0,0.7,0l-0.2,0C27.7,5.1,27.2,5,26.7,5c-0.2,0-0.5,0-0.7,0l0,0 c-5.4,0-8.7,2.3-9.3,2.7l-0.1,0.1l-0.1,0.1c-0.6,0.2-3.7,1.5-4.3,4.2L12,12.4l-0.4,0.2c-0.4,0.2-0.7,0.5-0.9,0.9 c-0.7,1.2-0.8,3-0.3,5.5l0.1,0.5L10.1,20c0,0-0.1,0.1-0.1,0.1C10.1,20,10.2,19.9,10.3,19.8z"
                          />
                          <path
                            fill="#263238"
                            d="M28 41H34V43H28zM14.2 22.4c-.1-.9-.4-1.7-1.1-2.1-.3-.1-.5-.2-.8-.2-.5 0-1.1.2-1.6.9l-.7-2c.9-.5 1.7-.8 2.5-.8.5 0 1 .1 1.5.3.4.2.8.5 1.1.8-.2-1.4.1-2.9.5-4.1.2-.5.3-1 .4-1.3.1-.6 0-.7-.2-1-1.9.2-3.5.7-3.5.7l-.5-1.9c.1 0 2.1-.6 4.4-.8l.7-.1.3.6c0 0 .1.1.1.2.3.3.7.9.8 1.9 0 .2 0 .6-.1.9-.1.5-.3.9-.4 1.5-.4 1.2-.7 2.6-.3 3.6.2.6.3 1.2.4 1.6.1.4.2 1.3-.2 1.6-.4.3-2.7 1-2.7 1S14.3 23.3 14.2 22.4z"
                          />
                          <path
                            fill="#263238"
                            d="M17.5,12.6l-1.8-0.8c0-0.1,3.7-8.2,13-7.6l-0.1,2C20.6,5.8,17.5,12.6,17.5,12.6z"
                          />
                          <path
                            fill="#fff"
                            d="M22,36.2c-5.1,0-6.8-2.7-6.8-2.7s-0.3,1.6,1.1,3.4c2.1,2.5,5.3,2.8,5.3,2.8"
                          />
                          <path
                            fill="#263238"
                            d="M21.5 40.7c-.1 0-3.6-.4-5.9-3.2-1.7-2.2-1.4-4-1.3-4.2l.5-2.6 1.4 2.3c.1.1 1.5 2.2 5.9 2.2v2c-2.1 0-3.7-.4-4.9-1 0 0 0 0 0 .1 1.8 2.1 4.5 2.4 4.6 2.4L21.5 40.7zM34.7 42c-.1 0-.2 0-.3 0-1.8 0-3.5-.6-3.6-.6l.7-1.9c0 0 1.4.5 2.8.5l.1 0c.5 0 1 0 1.3-.1C36 39.7 36 39 36 38.6c0-.4-.1-.9-.1-1.3l0-.3c-.1-.8-.4-1.1-1.2-1.1-.6.1-2.5 1.5-3.2 2L31 38.3l-1.2-1.6.4-.3c1.9-1.4 3.4-2.4 4.4-2.4 1.8 0 2.9 1 3.2 2.8l0 .3c.1.4.1 1 .2 1.5 0 1.5-.4 2.6-1.4 3C35.9 42 35.3 42 34.7 42z"
                          />
                          <path
                            fill="#263238"
                            d="M24.3 43.6c-.1 0-.2 0-.3 0-2.9-.2-3.1-2.5-3.2-4.6 0-.4-.1-.8-.1-1.2-.2-1.6.5-2.4 1-2.8 1.3-.9 3.3-.5 4.8.2l3 1.6-.9 1.8-3-1.6c-1.2-.6-2.4-.7-2.7-.4-.2.2-.2.6-.2.9.1.4.1.9.1 1.3.2 2.4.4 2.7 1.4 2.8 2.1.2 4.2-1.8 4.2-1.9l1.4 1.4C29.6 41.3 27.2 43.6 24.3 43.6zM21 15.4c0 0-.2.6-.6.6-.2 0-.4-.4-.4-.8 0-1.2.8-2.7 1.6-3.4.6-.5 1.4-.8 2.3-.8.4 0 .9 0 1.3.2.5.2.8.6.8 1.4 0 0 0 .4-1.3.4-.8 0-1.8.1-2.2.5C22.1 13.9 21.5 14.3 21 15.4zM26.4 21c-.5 0-1.1-.1-1.6-.2-.6-.1-.9-.7-.7-1.2l.2-.3c.1-.2.3-.3.5-.3.1 0 .2 0 .3 0 .8.2 2 .2 2.4.2-.5-1.9-.4-3.3-.1-3.3s1.1 2.3 1.5 2.8c.4.5.3 1.1-.1 1.5C28.1 20.8 27.3 21 26.4 21zM36.4 21c-.2 0-.3 0-.5 0-.6-.1-.9-.5-.9-1.1 0 0 .1-.9.6-.9.4 0 .9.2 1.1.2C36.5 18 36.2 16 36.5 16c.3 0 1 2.4 2.4 3.3.4.3.4.9-.2 1.3C37.9 20.9 37.2 21 36.4 21zM38.3 14.5c-.2-.8-1.1-1.5-2.1-1.8C34.9 12.4 34 13 34 13c0-.9.7-1.6 1-1.7.4-.2.6-.3 1-.3 2.1 0 2.9 1.3 3.6 2.6L38.3 14.5z"
                          />
                          <path
                            fill="#263238"
                            d="M31.9 27c-.1 0-.3 0-.4 0 0 0 0 0 0 0-.7 0-1.3-.2-1.7-.7-.8-.9-.9-2.1-.6-2.3.3-.2.5.5 1.5.8 0 0 .7.1.9.1.2 0 .3 0 .5 0 1.3 0 2.4-.3 2.8-.6C35 24.2 35 24 35 24c0 0 0-.3-1.3-1.5-1.3-1.2-1.7-2.7-1.7-4 0-1.5.2-2.5.4-2.5.3 0 .6 3.2 2.7 5 1.5 1.3 1.9 2.2 1.9 3.1 0 .9-.5 1.4-.8 1.7C35.1 26.8 33.3 27 31.9 27zM31.4 30c-3.1-.1-4.7-2-4.7-2s3.5.3 5.8.3V30H31.4zM33.6 30v-.9-.9c.2-.1 1.8.1 3.6-.6C36.8 29.7 33.7 30 33.6 30zM29.1 33c-2.2 0-3.3-.6-4.1-3.2 0 0-.1-.2.2-.3s.4.1.4.1c.4 1.1 2.1 1.4 4.2 1.4.1 0 .2 0 .3 0 1 0 3 0 3 0s-.4 2-2.5 2C30.3 33 29.3 33 29.1 33z"
                          />
                          <path
                            fill="#263238"
                            d="M29 37c-3.2 0-5.7-1-8.4-3.9-1.5-1.6-1.5-3.7-1.3-3.9s.5 1.2 1.7 2.5c2.4 2.6 5.8 3.4 8.7 3.2 3.8-.3 5.4-2.5 5.7-2.7l1.7 1.2c-.1.1-2.4 3.1-7.2 3.5C29.5 37 29.3 37 29 37zM13.4 29l-.1-1-.1-1c0 0 .2-.1.6-.4.3-.2.6-.2.9 0l.5.4c.3.3.3.8 0 1.1C14.3 28.9 13.5 29 13.4 29zM13 23l1.6.6c-.1-.4-.6-2.2-2-2.6-.4-.1-1.1.2-1.2.9 0 0 0 .2 0 .3 0 0 0 .1 0 .1C11.5 24 13 25 13 25V23z"
                          />
                          <path
                            fill="#263238"
                            d="M30,42c-1.7,0-3-1.3-3-3s1.3-3,3-3s3,1.3,3,3S31.7,42,30,42z M30,38c-0.5,0-1,0.4-1,1s0.4,1,1,1 s1-0.4,1-1S30.6,38,30,38z"
                          />
                          <path
                            fill="#263238"
                            d="M39.6,44H8.4c-0.4,0-0.8-0.2-0.9-0.6l-1.4-3.5C6,39.6,6,39.5,6,39.3l0-0.4c0.1-1,5.7-4.5,7.9-5.8 c-0.7-1.5-1.2-3.1-1.4-4.1c-0.8-0.1-1.8-0.5-3-1.7C8.4,26.2,8,24.3,8,23c0-0.9,0.2-2.4,1.4-3.7c-0.6-2.7-0.5-4.8,0.4-6.2 c0.4-0.7,0.9-1,1.3-1.3c0.8-3.1,4.1-4.5,4.9-4.9c0.8-0.6,4.5-3.1,10.7-2.9c8.4,0.3,13.6,5.5,14.2,14.2C41,19,41,19.8,41,20.6 c0,6.5-2.3,10.5-3.5,12.1c4.5,3.1,4.5,3.6,4.5,4.1c0.1,1.3-1.1,5.2-1.5,6.4C40.4,43.7,40,44,39.6,44z M9.1,42h29.7 c0.6-1.9,1.1-3.9,1.2-4.7c-0.6-0.6-2.7-2.2-4.6-3.4c-0.2-0.2-0.4-0.4-0.4-0.7s0.1-0.6,0.3-0.8c0,0,3.6-3.7,3.7-11.7 c0-0.7,0-1.5-0.1-2.3C38.2,8,30.9,6.2,26.6,6c-6-0.2-9.4,2.5-9.5,2.6c-0.1,0.1-0.2,0.1-0.3,0.2c0,0-3.5,1.2-3.8,3.9 c-0.1,0.4-0.4,0.8-0.8,0.9c-0.7,0.2-1.8,1.7-0.7,5.9c0.1,0.4,0,0.8-0.3,1c-1,0.8-1.1,2-1.1,2.6c0,1.2,0.4,2.4,1,3 c1.2,1.3,2.1,1.1,2.1,1.1c0.3-0.1,0.6,0,0.8,0.1c0.2,0.2,0.4,0.4,0.4,0.7c0,0,0.5,3.1,1.8,5.1c0.1,0.2,0.2,0.5,0.1,0.8 c-0.1,0.3-0.2,0.5-0.5,0.6c-0.7,0.4-6.3,4-7.7,5L9.1,42z M40.1,37.4L40.1,37.4L40.1,37.4z"
                          />
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 48 48"
                          width="40px"
                          height="40px"
                        >
                          <path
                            fill="#AFB42B"
                            d="M6,25c0,0,4.208,2,18,2s18-2,18-2v-4H6V25z"
                          />
                          <path
                            fill="#FFCC80"
                            d="M40,25c1.688,0.625,2,2,2,2c0,4.188-1.311,5.678-2,6c-0.938,0.438-4,1-4,1l1-12h3V25z M8,25c-1.688,0.625-2,2-2,2c0,4.188,1.311,5.678,2,6c0.938,0.438,4,1,4,1l-1-12H8V25z"
                          />
                          <path
                            fill="#FFE0B2"
                            d="M24 11A15 17 0 1 0 24 45A15 17 0 1 0 24 11Z"
                          />
                          <path
                            fill="#FFB74D"
                            d="M24 36A4 3 0 1 0 24 42A4 3 0 1 0 24 36Z"
                          />
                          <path
                            fill="#BCAAA4"
                            d="M40,22l-2,9l-3,2l-7-2l-2-4h-2h-2l-2,4l-7,2l-3-2l-2-9c0,0,6.375-2,16-2S40,22,40,22z"
                          />
                          <path
                            fill="#263238"
                            d="M34,28.89c-0.58-0.294-1.214,0.078-2.369,0.101c-0.86,0.032-1.581,0.042-1.628-1.372c-0.047-1.416,0.502-2.588,1.362-2.619c0.859-0.031,1.644,1.09,1.749,2.503c0.029,0.39-0.012,0.673-0.104,0.884C33.809,28.288,33.992,28.856,34,28.89L34,28.89L34,28.89z M19,27.722c0.013,1.373-0.714,1.29-1.589,1.299c-0.089,0.003-0.172,0.004-0.256,0.004c-0.097,0.003-0.192,0.004-0.282,0.002c-0.85-0.002-1.391-0.04-1.872,0.219c0.011-0.03,0.222-0.501,0.985-0.693c-0.084-0.185-0.137-0.443-0.151-0.801c-0.053-1.373,0.794-2.743,1.669-2.752c0.874-0.008,1.484,1.103,1.497,2.476l0,0L19,27.722L19,27.722z"
                          />
                          <path
                            fill="#FFF"
                            d="M18,26.5c0,0.276-0.224,0.5-0.5,0.5S17,26.776,17,26.5s0.224-0.5,0.5-0.5S18,26.224,18,26.5z M31.5,26c-0.276,0-0.5,0.224-0.5,0.5s0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5S31.776,26,31.5,26z"
                          />
                          <path
                            fill="#546E7A"
                            d="M39.749,23.601C39.482,23.517,34.75,21,24,21S8.518,23.517,8.251,23.601l-0.876,0.274l1.736,7.734l3.719,2.479l7.867-2.248L22.618,28H24h1.382l1.921,3.841l7.867,2.248l3.719-2.479l1.736-7.734L39.749,23.601z M37.111,30.391l-2.281,1.521l-6.133-1.752L26.618,26H24h-2.618l-2.079,4.159l-6.133,1.752l-2.281-1.521l-1.155-5.124C11.78,24.743,15.125,23,24,23s12.22,1.743,14.267,2.267L37.111,30.391z"
                          />
                          <path
                            fill="#D4E157"
                            d="M8,19c0-8.837,7.163-16,16-16s16,7.163,16,16c0,0-8.163-2-17-2S8,19,8,19z"
                          />
                          <path
                            fill="#D4E157"
                            d="M43,17c0,0-4.785-4-19-4S5,17,5,17c0,5.216,1,8,1,8s3.806-4,18-4s18,4,18,4S43,21.964,43,17z"
                          />
                          <path
                            fill="#BF360C"
                            d="M40 34l-3 5c-.313.469-.782.811-1.323.966l-5.027 1.943c-.714.204-1.483.062-2.077-.384L24 39l-4.572 2.525c-.594.446-1.363.588-2.077.384l-5.027-1.943C11.782 39.811 11.313 39.469 11 39l-3-5 4 2h4l5.151-3.104C21.534 34.112 22.657 35 24 35c1.33 0 2.445-.872 2.838-2.07L32 36h4L40 34zM26.758 21.055C25.884 21.02 24.968 21 24 21c-9.94 0-14.782 1.961-16.793 3.136C6.487 25.261 3.653 29.768 3 32c4.033-5.768 11.585-9.073 15.104-10.364C16.947 22.483 14.861 24.097 13 26 16.688 23.447 25.217 21.409 26.758 21.055zM28.154 21.127C27.232 22.174 25.528 23.692 22 26c5.959-.179 12.358-3.645 12.942-3.968C33.126 21.63 30.888 21.298 28.154 21.127zM20 8L20 11 22 11 22 10 23 10 23 15 22 15 22 17 26 17 26 15 25 15 25 10 26 10 26 11 28 11 28 8z"
                          />
                          <path
                            fill="#AFB42B"
                            d="M39.531,15.253c-0.176-0.721-0.385-1.43-0.653-2.111c-1.35-0.464-2.834-1.073-5.878-1.509v5.88C31.164,17.331,27.378,17,24,17s-7.164,0.331-9,0.514v-5.853c-2.303,0.409-4.572,1.064-5.888,1.509c-0.263,0.672-0.469,1.371-0.643,2.083c1.164-0.432,2.661-0.876,4.531-1.255v6.001C15.775,19.416,19.371,19,24,19s8.225,0.416,11,0.999v-6.001C36.869,14.377,38.367,14.821,39.531,15.253z"
                          />
                        </svg>
                      </div>
                      <div>
                        <img
                          src={code3}
                          alt=""
                          width="15px"
                          height="15px"
                          style={{
                            position: 'absolute',
                            transform: 'translate(-50%,20%)',
                            left: '46.3%',
                          }}
                          className="dashedlineresponsive2"
                        />
                      </div>
                      <h3
                        style={{ color: '#2f55d4' }}
                        className="font-primary m-0  text-left text-[20px] font-semibold leading-[32px] transition transition-colors text-indigo-500"
                      >
                        Integrate Inofinix with any CI provider
                      </h3>
                      <p className="mt-[8px] overflow-hidden text-[16px] leading-[24px] text-gray-700">
                        Easily integrate Inofinix with your current CI provider.
                        Run Inofinix in CI and <br></br>you will know as soon as
                        there is a failure. We make it simple to run in CI.{' '}
                        <br></br>Use our Docker images or bring your own.
                      </p>
                      <Link
                        className="mt-[12px] flex items-center gap-[8px] text-[16px] font-medium leading-[24px] text-indigo-500"
                        to={{ pathname: 'https://inofinix.io/index' }}
                        target="_blank"
                      >
                        {' '}
                        Learn more
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="m9 12 4-4m0 0L9 4m4 4H3"
                            stroke="currentColor"
                            className="icon-dark"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <Sectiontwo />
              <Sectionthree />
            </section>
          </Container>
        </div>
        <Popup />
      </React.Fragment>
    );
  }
}

export default Product;
