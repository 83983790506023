// React Basic and Bootstrap
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Collapse,
} from "reactstrap";
import terms from '../../../pages/Pages/terms.jpg'
import AOS from "aos";
import "aos/dist/aos.css";


//Import Icons
import FeatherIcon from "feather-icons-react";
import Aos from "aos";

class PageTerms extends Component {
  constructor(props) {
    super(props);
    this.state = {

      col1: true,
      col2: false,
      col3: false,
      col4: false,
      col5: false,
    };
    this.t_col1 = this.t_col1.bind(this);
    this.t_col2 = this.t_col2.bind(this);
    this.t_col3 = this.t_col3.bind(this);
    this.t_col4 = this.t_col4.bind(this);
    this.t_col5 = this.t_col5.bind(this);
  }

  t_col1() {
    this.setState({
      col1: !this.state.col1,
      col2: false,
      col3: false,
      col4: false,
      col5: false,
    });
  }
  t_col2() {
    this.setState({
      col2: !this.state.col2,
      col1: false,
      col3: false,
      col4: false,
      col5: false,
    });
  }
  t_col3() {
    this.setState({
      col3: !this.state.col3,
      col2: false,
      col1: false,
      col4: false,
      col5: false,
    });
  }
  t_col4() {
    this.setState({
      col4: !this.state.col4,
      col2: false,
      col3: false,
      col1: false,
      col5: false,
    });
  }
  t_col5() {
    this.setState({
      col5: !this.state.col5,
      col2: false,
      col3: false,
      col1: false,
      col4: false,
    });
  }

  componentDidMount() {
    document.body.classList = "";
    document.getElementById("topnav").classList.add("bg-secondary-x");
    window.addEventListener("scroll", this.scrollNavigation, true);
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById("topnav");
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        // document.querySelector(".shoppingbtn").classList.remove("btn-light");
        // document.querySelector(".shoppingbtn").classList.add("btn-primary");
        document.getElementById("topnav").classList.add("nav-sticky");
        document.getElementById("topnav").classList.remove("bg-secondary-x");
      } else {
        // document.querySelector(".shoppingbtn").classList.remove("btn-primary");
        // document.querySelector(".shoppingbtn").classList.add("btn-light");
        document.getElementById("topnav").classList.remove("nav-sticky");
        document.getElementById("topnav").classList.add("bg-secondary-x");
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <section className="section">
          <Container >
            <Row className="justify-content-center" style={{ marginRight: '33px', marginTop: '50px' }}>

              <Col lg={3} className="terms1sresp" >
                <div>
                  <nav id="sidebar" style={{ marginLeft: '13px', marginTop: '30px', fontSize: 'larger', display: 'flex', justifyContent: 'end' }}>
                    <ul className="list-group">
                      <li className="list-group-item" >
                        <a href="/privacy-policy"> Privacy policy</a>
                      </li>
                      <li className="list-group-item active" aria-current="true">
                        <a href="/terms-of-use" style={{ color: 'white' }}> Terms of use</a>
                      </li>
                      <li className="list-group-item">
                        <a href="/cookies-and-policies" >Cookies Policy</a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Col>
              <Col lg={9}>
                <Card className="shadow border-0 rounded">
                  <CardBody>
                    <h2>Terms of Use for Finixcloud</h2>
                    <p><em>Last Updated: 12-Oct-2023</em></p>
                    <p>By accessing or using Finixcloud and its related services (the "Services"), you agree to be bound by these Terms of Use. If you do not agree with these terms, please do not use our Services.</p>

                    <h5>1. Acceptance of Terms</h5>
                    <p style={{ marginLeft: '1.5em' }}><strong>1.1. Agreement:</strong> </p>
                    <p style={{ marginLeft: '1.5em' }}>These Terms of Use constitute a legally binding agreement between you and INOFINIX PVT LTD, a company registered in India ("<strong>we</strong>," "<strong>us</strong>," or "<strong>Finixcloud</strong>").</p>
                    <p style={{ marginLeft: '1.5em' }}><strong>1.2. Updates:</strong> </p>
                    <p style={{ marginLeft: '1.5em' }}>We may update these Terms of Use from time to time. Any changes will be effective upon posting the revised terms on our platform. Your continued use of our Services after such changes will constitute your acceptance of the updated terms.</p>

                    <h5>2. User Obligations</h5>
                    <p style={{ marginLeft: '1.5em' }}><strong>2.1. Eligibility:</strong></p>
                    <p style={{ marginLeft: '1.5em' }}>You must be at least 18 years of age to use our Services. By using our Services, you represent and warrant that you meet this age requirement.</p>
                    <p style={{ marginLeft: '1.5em' }}><strong>2.2. Registration:</strong></p>
                    <p style={{ marginLeft: '1.5em' }}>If required, you agree to provide accurate and complete information when registering for our Services. You are responsible for maintaining the confidentiality of your account and password.</p>
                    <p style={{ marginLeft: '1.5em' }}><strong>2.3. Prohibited Activities:</strong></p>
                    <p style={{ marginLeft: '1.5em' }}>You agree not to engage in any of the following activities when using our platform:</p>

                    <ul style={{ marginLeft: '12px' }}>
                      <li>Violating any applicable laws or regulations.</li>
                      <li>Infringing upon our intellectual property or the intellectual property of others.</li>
                      <li>Uploading, posting, or transmitting any harmful or malicious content.</li>
                      <li>Attempting to gain unauthorized access to our platform or any other user's account.</li>
                      <li>Engaging in any activity that disrupts or impairs the functionality of our platform.</li>
                    </ul>

                    <h5>3. Intellectual Property</h5>
                    <p style={{ marginLeft: '1.5em' }}><strong>3.1. Ownership:</strong></p>
                    <p style={{ marginLeft: '1.5em' }}>All content and intellectual property rights on our platform, including but not limited to text, graphics, and software, are owned by INOFINIX PVT LTD or its licensors.</p>
                    <p style={{ marginLeft: '1.5em' }}><strong>3.2. License:</strong> </p>
                    <p style={{ marginLeft: '1.5em' }}>We grant you a limited, non-exclusive, and non-transferable license to use our platform for its intended purpose, subject to these Terms of Use.</p>

                    <h5>4. Termination</h5>
                    <p>We may terminate or suspend your access to our platform at our discretion, with or without cause, and with or without notice.</p>

                    <h5>5. Disclaimers and Limitations of Liability</h5>
                    <p style={{ marginLeft: '1.5em' }}><strong>5.1. No Warranties:</strong> </p>
                    <p style={{ marginLeft: '1.5em' }}>We provide our Services "as is," and we make no warranties or representations about the accuracy or completeness of the content on our platform.</p>
                    <p style={{ marginLeft: '1.5em' }}><strong>5.2. Limitation of Liability:</strong></p>
                    <p style={{ marginLeft: '1.5em' }}>To the fullest extent permitted by law, INOFINIX PVT LTD and its affiliates shall not be liable for any indirect, incidental, consequential, special, or punitive damages, or for any lost profits or revenues.</p>

                    <h5>6. Governing Law and Dispute Resolution</h5>
                    <p style={{ marginLeft: '1.5em' }}><strong>6.1. Governing Law:</strong></p>
                    <p style={{ marginLeft: '1.5em' }}>These Terms of Use are governed by and construed in accordance with the laws of Bangalore, Karnataka, India.</p>
                    <p style={{ marginLeft: '1.5em' }}><strong>6.2. Dispute Resolution:</strong></p>
                    <p style={{ marginLeft: '1.5em' }}>Any disputes or claims arising out of these Terms of Use will be subject to the exclusive jurisdiction of the courts in Bangalore, Karnataka, India.</p>

                    <h5>7. Contact Us</h5>
                    <p>If you have any questions or concerns regarding these Terms of Use, please contact us at <a href="mailto:info@inofinix.io">info@inofinix.io</a>.</p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
export default PageTerms;
