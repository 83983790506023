// React Basic and Bootstrap
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container, CardBody, Card } from "reactstrap";

//Import Icons
import FeatherIcon from "feather-icons-react";
import privacies from '../../../pages/Pages/privacies.jpg'
import AOS from "aos";
import "aos/dist/aos.css";


class PagePrivacy extends Component {
  componentDidMount() {
    document.body.classList = "";
    window.addEventListener("scroll", this.scrollNavigation, true);
    document.getElementById("topnav").classList.add("bg-secondary-x");
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById("topnav");
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        // document.querySelector(".shoppingbtn").classList.remove("btn-light");
        // document.querySelector(".shoppingbtn").classList.add("btn-primary");
        document.getElementById("topnav").classList.add("nav-sticky");
        document.getElementById("topnav").classList.remove("bg-secondary-x");
      } else {
        // document.querySelector(".shoppingbtn").classList.remove("btn-primary");
        // document.querySelector(".shoppingbtn").classList.add("btn-light");
        document.getElementById("topnav").classList.remove("nav-sticky");
        document.getElementById("topnav").classList.add("bg-secondary-x");
      }
    }
  };

  render() {
    return (
      <React.Fragment>

        {/* breadcrumb */}
        {/* <section className="bg-half-170 bg-light d-table w-100 d-print-none"
         style={{ background: `url(${privacies  })` }} >
            <Container data-aos='fade-up'>
                <Row className="mt-5 justify-content-center">
                    <Col lg={9} className="text-center">
                        <div className="pages-heading">
                            <h4 className="title" style={{color:'white'}}> Privacy Policy </h4>
                            <ul className="list-unstyled mt-4 mb-0">
                            </ul>
                        </div>
                    </Col>  
                </Row>
                
            </Container> 
        </section>

        <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div> */}

        <section className="section">
          <Container style={{ marginTop: "-60px" }}>
            <Row className="rounded bg-white pt-5 pl-5 pr-5 pb-4 col-xs-12.col-md-12 justify-content-center" style={{ marginLeft: '-68px', marginTop: '58px' }}>
              <Col lg={3} className="privacy1sresp">
                <div>
                  <nav id="sidebar" style={{ marginLeft: '13px', marginTop: '30px', fontSize: 'larger', display: 'flex', justifyContent: 'end' }}>
                    <ul className="list-group">
                      <li className="list-group-item active" aria-current="true" >
                        <a href="/privacy-policy" style={{ color: 'white' }}> Privacy policy</a>
                      </li>
                      <li className="list-group-item">
                        <a href="/terms-of-use"> Terms of use</a>
                      </li>
                      <li className="list-group-item">
                        <a href="/cookies-and-policies" >Cookies Policy</a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Col>
              <Col lg={9} className="privacyeresp">
                <Card className="card shadow rounded border-0">
                  <CardBody>
                    <h2>Privacy Policy for Finixcloud</h2>
                    <p><em>Last Updated: 12-Oct-2023</em></p>
                    <p><strong>INOFINIX PVT LTD</strong>, a company registered in Bangalore (India), ("<strong>we</strong>," "<strong>us</strong>," or "<strong>FInixcloud</strong>") is committed to protecting your privacy. This Privacy Policy outlines our practices regarding the collection, use, and sharing of your personal information in connection with your use of Finixcloud and any related services (collectively, the "<strong>Services</strong>").</p>

                    <h4>1. Information We Collect</h4>
                    <p style={{ marginLeft: '27px' }}>We may collect various types of information when you use Finixcloud, including but not limited to:</p>
                    <ul style={{ marginLeft: '14px' }}>
                      <li><strong>Personal Information:</strong> This may include your name, email address, phone number, and other contact information that you provide when you sign up for our Services or communicate with us.</li>
                      <li><strong>Usage Information:</strong> We collect information about how you interact with our platform, such as your IP address, browser type, pages viewed, and the date and time of your visit.</li>
                      <li><strong>Device Information:</strong> We may collect information about the device you use to access our Services, such as the type of device, operating system, and device identifiers.</li>
                      <li><strong>Cookies and Similar Technologies:</strong> We use cookies and similar technologies to collect data about your use of Finixcloud. You can manage your cookie preferences using your browser settings.</li>
                    </ul>

                    <h4>2. How We Use Your Information</h4>
                    <p style={{ marginLeft: '27px' }}>We may use your information for the following purposes:</p>
                    <ul style={{ marginLeft: '14px' }}>
                      <li>To provide and maintain our Services.</li>
                      <li>To communicate with you, including sending important notices, updates, and promotional materials.</li>
                      <li>To improve our platform and user experience.</li>
                      <li>To analyze usage patterns and trends.</li>
                      <li>To comply with legal obligations.</li>
                    </ul>

                    <h4>3. How We Share Your Information</h4>
                    <p style={{ marginLeft: '27px' }}>We may share your information with third parties under the following circumstances:</p>
                    <ul style={{ marginLeft: '14px' }}>
                      <li>With your consent.</li>
                      <li>To third-party service providers who assist us in providing and maintaining our Services.</li>
                      <li>To comply with legal obligations or to protect our rights, privacy, safety, or property.</li>
                      <li>In connection with a business transaction, such as a merger, acquisition, or sale of assets.</li>
                    </ul>

                    <h4>4. Your Privacy Choices</h4>
                    <ul style={{ marginLeft: '14px' }}>
                      <li><strong>Access and Correction:</strong> You have the right to access and correct your personal information stored on our platform.</li>
                      <li><strong>Opt-Out:</strong> You can opt out of receiving promotional communications from us by following the instructions provided in such communications.</li>
                      <li><strong>Cookies:</strong> You can manage your cookie preferences through your browser settings.</li>
                    </ul>

                    <h4>5. Security</h4>
                    <p style={{ marginLeft: '27px' }}>We take reasonable measures to protect your personal information. However, please be aware that no data transmission over the internet is completely secure.</p>

                    <h4>6. Changes to this Privacy Policy</h4>
                    <p style={{ marginLeft: '27px' }}>We may update this Privacy Policy from time to time. Please review the "<strong>Last Updated</strong>" date at the top of this policy to determine when it was last revised.</p>

                    <h4>7. Contact Us</h4>
                    <p style={{ marginLeft: '27px' }}>If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:info@inofinix.io">info@inofinix.io</a>.</p>
                  </CardBody>
                </Card>
              </Col>

            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
export default PagePrivacy;
